/* @font-face {
  font-family: "Config Rounded";
  font-weight: 900;
  src: local("Config Rounded"), url(./assets//fonts//ConfigRounded-Bold.otf) format("truetype");
} */
#root {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

@font-face {
  font-family: "DiodrumArabic-Bold";
  font-weight: 900;
  src: local("DiodrumArabic-Bold"), url(./assets/fonts/DiodrumArabic-Bold.ttf) format("truetype");
}

@media (prefers-reduced-motion: no-preference) {

  /* .img1 {
    animation: image-spin-y 2 0.5s linear;
  } */
  .img3 {
    animation: image-zoom-in 1 2s linear;
  }

  .img4 {
    animation: image-zoom-in 1 1s linear;
  }

  .flag {
    animation: flag-animation infinite 2s linear;
  }

  .flag2 {
    animation: flag-animation-2 infinite 2s linear;
  }

  .flag:hover {
    animation: flag-animation infinite 1s linear;
  }

  .img6 {
    animation: badge-animation infinite 2s linear;
  }
}

@keyframes image-spin-y {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

@keyframes image-zoom-in {
  0% {
    transform: scale(0, 0);
  }

  50% {
    transform: scale(0.5, 0.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes flag-animation {
  0% {
    transform: scale(0.95, 0.95);
  }

  50% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(0.95, 0.95);
  }
}

@keyframes flag-animation-2 {
  0% {
    transform: scale(0.8, 0.8);
  }

  50% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(0.8, 0.8);
  }
}

@keyframes badge-animation {
  0% {
    transform: scale(0.9, 0.9);
  }

  50% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(0.9, 0.9);
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #BE9455;
  border-radius: 25px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 0;
}

::selection {
  background: #be945579;
  color: inherit;
}

.App {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
  z-index: 0;
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Page {
  height: 100%;
  /* max-width: 80vw; */
  background-color: white;

}

.hidden {
  display: none;
  visibility: hidden;

}

.img1 {
  height: 20vmin;
  pointer-events: none;
  padding: 3vmin;
  z-index: 1;
}

.img2 {
  height: 20vmin;
  pointer-events: none;
  z-index: 1;
}

.img3 {
  width: 100%;
  pointer-events: none;
  height: auto;
}

.img4 {
  max-width: 100%;
  height: auto;
}

.img5 {
  width: 100%;
  height: auto;
}

.img6 {
  cursor: pointer;
  position: static;
  color: white;
  display: block;
  /* Change display to block for margin auto to work */
  margin-left: auto;
  /* Center the element horizontally */
  margin-right: auto;
  margin-top: -5%;
  /* Center the element horizontally */
  height: 7vw;
  width: auto;
  /* A
}

.img7 {
  width: 100%;
  height: auto;
}

.img8 {
  cursor: pointer;
  position: absolute;
  color: white;
  bottom: 10%;
  /* position the top  edge of the element at the middle of the parent */
  right: 37%;
  /* position the left edge of the element at the middle of the parent */
  width: auto;
  height: 10vw;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.img9 {
  height: 10%;

  position: absolute;
  top: -10%;
  /* position the top  edge of the element at the middle of the parent */
  left: 45%;
  /* position the left edge of the element at the middle of the parent */
  width: auto;
  transform: translate(-50%, -50%);
  z-index: 1;
}

​​ .jss_1 {}

.jss_2 {
  text-align: center;
  min-height: inherit;
  overflow: hidden;
  padding: 0 !important;
}

.jss_3 {
  position: absolute;
  top: 0;
  left: 0;
}

.jss_4 {
  position: absolute;
  top: 0;
  right: 0;
}

.jss_5 {
  margin: 0 0 5vmin 0;
}

.jss_6 {
  padding: 20% 20% 20% 20%;
}

.jss_7 {
  min-width: auto;
  /* margin-top: -20%; */
  width: 30%;
  height: 10%;
}

.jss_7:hover {
  opacity: 1;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.jss_8 {
  cursor: pointer;
  height: 30vmin;
  width: 50vmin;
  margin-bottom: 5rem;
}

.jss_9 {
  position: relative;
  width: 100%;
  height: 100%;
}

.jss_10 {
  transform: translateY(30%);
  margin-top: -30%;
  position: relative;
}

.jss_11 {
  width: 50%;
  text-align: end;
}

.jss_12 {
  overflow: hidden;
  bottom: 0;
  width: 100%;
  position: absolute;
}

.jss_13 {
  /* background: url("assets/img/toppng1.png"); */
}

.jss_14 {
  height: 90vmin;
}

.jss_15 {
  height: inherit;
  width: 60vmin;
}

.jss_16 {
  height: 20%;
}

.jss_17 {
  height: 80%;
}

.jss_18 {
  width: 50vmin;
  background-color: white;
}

.jss_19 {
  /* background: url("assets/img/formBackground.png"); */
  background-size: cover;
}

.jss_20 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jss_21 {
  max-width: -webkit-fill-available !important;
}

.jss_22 {
  padding: 5% 5% 5% 5%;
  width: 100%;
}

.jss_23 {
  max-width: 100%;
  height: auto;
  background: white;
  box-shadow: 4px 4px 64px #BE9455;
  padding: 10%;
  margin: -5% 10% 10% 10%;
  z-index: 0;
  border-radius: 5px;
}

.jss_24 {
  margin-bottom: 5% !important;
}

.jss_25 {
  /* margin-bottom: 5% !important; */
  padding: 6px 16px 16px !important;
}

.jss_26 {
  padding: 5%;
}

.jss_27 {
  width: 100%;
  height: 100%;
}

.jss_28 {
  justify-content: space-between !important;
  display: flex !important;
  flex-direction: row !important;
}

.jss_29 {
  text-align: start;
}

.jss_30 {
  text-align: end;
}

.jss_31 {
  color: #ff4949;
  font-size: 3vmin;
}

.jss_32 {
  margin-bottom: 10% !important;
  width: 100%;
  padding: 6px 16px 16px !important;
  font-size: 1.5rem !important;
}

.jss_33 {
  display: flex;
  margin: 0 0 3% 0;
}

.jss_34 {
  margin-right: 5% !important;
}

.jss_35 {
  width: 20%;
}

.jss_36 {
  color: #0176ff !important;
}

.jss_37 {
  color: #BE9455 !important;
}

.jss_40 {
  display: none !important;
}

.jss_41 {
  width: 100%;
  text-align: center;
}

.jss_42 {}

.jss_43 {
  position: relative;
  font-family: Arial;
  z-index: 1;
}

.jss_44 {
  position: relative;
  text-align: center;
  width: 100%;
  color: white;
  /* top: 40%; */
  /* position the top  edge of the element at the middle of the parent */
  /* left: 50%; */
  /* position the left edge of the element at the middle of the parent */

  /* transform: translate(-50%, -50%); */
}

.jss_45 {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
}

.jss_46 {
  margin-bottom: 60%;
}

.jss_47 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 200px !important;
}

.jss_48 {
  /* height: 100%; */
  position: relative;
  width: 100%;
  margin: 2% 0 2% 0;
}

.jss_49 {
  margin-top: 5% !important;
  width: 50%;
  min-width: 50%;
  font-size: 1.5rem !important;
  padding: 6px 16px 16px !important
}

@media (min-width: 550px) {
  .jss_38 {
    width: 30%;
  }

  .jss_39 {
    width: 65%;
  }
}

@media (max-width: 750px) {

  /* mobile only styles */

  .img5 {
    width: 110%;
    margin-left: -5%;
  }

  .jss_22 {
    padding: 0;
  }

  .jss_23 {
    margin: -5% 3% 10% 3%;
    padding: 3%;
  }

  .jss_25 {
    width: 30%;
    padding: 6px 16px 4px !important;
    font-size: 2vmin !important;
    margin-bottom: 0 !important;
  }

  .jss_28 {
    /* justify-content: flex-start !important;
    display: flex !important;
    flex-direction: column !important; */
  }

  .jss_32 {
    padding: 6px 16px 6px !important;
    min-height: 50px;
  }

  .jss_38 {
    width: 30%;
  }

  .jss_39 {
    width: 65%;
  }

  .MuiButton-root {
    /* font-size: 2vmin !important; */
  }

  .jss_49 {
    margin-top: 5% !important;
    height: 10%;
    min-width: 50% !important;
    font-size: 2vmin !important;
    padding: 6px 16px 6px !important
  }
}

@media (min-width: 750px) and (max-width: 900px) {
  .jss_22 {
    padding: 5% 5% 5% 5%;
  }
}

@media (min-width: 900px) {
  .jss_22 {
    padding: 5% 5% 5% 5%;
  }
}